import Image from 'next/image'
import { useTranslations } from 'next-intl'
import { useSearchParams } from 'next/navigation'

import { CategoryByGoal } from '@/api'
import { gtmTracker } from '@/common/services/tracking/google/gtm-tracker'
import { selectors } from '@/common/constants/selectors-constants'
import { Link } from '@/components/link/link'
import { CategoryId } from '@/common/constants/categories'
import { SearchParams } from '@/common/constants/url-constants'

export type CategoriesByGoalProps = {
  categories: CategoryByGoal[]
  title?: string
}

const CategoriesToRemoveForTikTok = [
  CategoryId.LoseWeight,
  CategoryId.BuildMuscle,
]

export function CategoriesByGoal({
  categories,
  title,
}: CategoriesByGoalProps): JSX.Element {
  const t = useTranslations('CategoriesByGoal')
  const searchParams = useSearchParams()
  const isTikTok = searchParams.has(SearchParams.TikTok)

  const filteredCategories = isTikTok
    ? categories.filter(
        ({ category }) =>
          category?.uid &&
          !CategoriesToRemoveForTikTok.includes(category.uid as CategoryId),
      )
    : categories

  return (
    <div className="w-full bg-white mb-4">
      <h3
        data-test={selectors.HP.categoriesHeading}
        className="text-black font-bold text-base leading-4 my-4"
      >
        {title ?? t('title')}
      </h3>
      <ul
        data-test={selectors.HP.categoriesByGoal}
        className="grid grid-cols-2 lg:grid-cols-4 gap-2"
      >
        {filteredCategories.map(
          (categoryByGoal, index) =>
            categoryByGoal.category && (
              <li
                key={categoryByGoal.custom_name}
                onClick={() => gtmTracker.trackCategoryClick(index + 1)}
                className="flex flex-col items-center overflow-hidden sm:bg-[#e9e9e9]"
              >
                <Link
                  isExternal
                  href={categoryByGoal.category.url ?? ''}
                  className="h-full w-full sm:flex items-center hover:underline justify-between"
                  aria-label={categoryByGoal.custom_name ?? 'breadcrumb link'}
                >
                  {categoryByGoal.category.thumbnail && (
                    <Image
                      src={`https://gymbeam.cz/media/catalog/category/${categoryByGoal.category.thumbnail}`}
                      alt={categoryByGoal.custom_name ?? ''}
                      width={400}
                      height={400}
                      className="max-w-400 max-h-400 sm:w-[75px] sm:h-[75px] bg-[#e9e9e9] sm:ml-2"
                    />
                  )}
                  <div className="w-1/4 hidden sm:block lg:hidden h-full" />
                  <span
                    className="block w-full p-4 sm:text-left sm:uppercase text-xs font-bold sm:pl-2 text-center"
                    dangerouslySetInnerHTML={{
                      __html: categoryByGoal.custom_name ?? '',
                    }}
                  />
                </Link>
              </li>
            ),
        )}
      </ul>
    </div>
  )
}
