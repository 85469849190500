import Image from 'next/image'

import { BlogPostFragment } from '@/api'
import { Heading } from '@/components/heading'
import { Paragraph } from '@/components/paragraph'

interface BlogPostProps {
  blogPost: BlogPostFragment
}

export const BlogPost = ({ blogPost }: BlogPostProps) => {
  const image = blogPost?.img ?? ''
  const title = blogPost?.title ?? ''
  const content = blogPost?.excerpt ?? ''
  const url = blogPost?.url ?? ''

  return (
    <a
      href={url}
      aria-label={title}
      className="block text-black m-3 slick-slide-content no-underline hover:no-underline border-0"
      style={{ outline: 0 }}
    >
      <Image
        src={
          // ugly hack because of wrong urls from BE
          new URL(image.replace(/.*\/blog\//, '/blog/'), 'https://gymbeam.com')
            .href
        }
        width={500}
        height={500}
        priority
        alt="Blog post image"
        className="w-full h-auto"
      />
      <div>
        <Heading
          className="font-bold leading-5 mt-4 mb-2.5 text-[0.950rem]"
          variant="h3"
        >
          {title}
        </Heading>
        <Paragraph className="text-justify">{content}</Paragraph>
      </div>
    </a>
  )
}
