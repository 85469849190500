import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/app/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/app/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/app/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/app/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/app/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/app/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/app/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo/GB_Logo_empty.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo/GB_Logo_Energy_BA.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo/GB_Logo_Energy_BG.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo/GB_Logo_Energy_COM.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo/GB_Logo_Energy_CZ.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo/GB_Logo_Energy_DE.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo/GB_Logo_Energy_GR.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo/GB_Logo_Energy_HR.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo/GB_Logo_Energy_HU.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo/GB_Logo_Energy_IT.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo/GB_Logo_Energy_PL.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo/GB_Logo_Energy_RO.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo/GB_Logo_Energy_RS.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo/GB_Logo_Energy_RU.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo/GB_Logo_Energy_SI.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo/GB_Logo_Energy_SK.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo/GB_Logo_Energy_UA.png");
;
import(/* webpackMode: "eager", webpackExports: ["FooterStoreMenu"] */ "/app/src/common/components/footer/components/footer-store-menu/footer-store-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SubscriberEmail"] */ "/app/src/common/components/footer/components/subscriber-email.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/common/components/footer/footer-legacy.css");
;
import(/* webpackMode: "eager" */ "/app/src/common/components/footer/replace/components/cetified-shops-badge.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/common/components/footer/replace/components/cookie-settings-renderer.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/common/components/footer/replace/components/heureka-badge.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/common/components/footer/replace/components/trustpilot-badge.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Autocomplete"] */ "/app/src/common/components/header/components/autocomplete/Autocomplete.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/common/components/header/components/header-context.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/common/components/header/components/header-controls-desktop.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/common/components/header/components/header-hamburger.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/common/components/header/components/header-navigation/header-navigation-component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderWrapper"] */ "/app/src/common/components/header/components/header-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/common/components/header/components/language-switcher/language-switcher-component.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/common/components/header/components/ribbon/ribbon-component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/app/src/common/components/link/link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientOnlyComponent"] */ "/app/src/common/components/wrappers/client-only-component.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/common/components/wrappers/desktop-only-component.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/common/components/wrappers/mobile-only-component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["gtmTracker","trackAbTestSetup"] */ "/app/src/common/services/tracking/google/gtm-tracker.ts");
;
import(/* webpackMode: "eager", webpackExports: ["PageTracking"] */ "/app/src/common/services/tracking/page/page-tracking.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomepageBanner"] */ "/app/src/modules/homepage/components/banner/homepage-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomepageClient"] */ "/app/src/modules/homepage/homepage-client.tsx");
